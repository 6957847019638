import React from 'react';
import { Text } from 'grommet';
import { Box } from './layout/Box';
import styled from 'styled-components';
type Props = {
  data: { text?: string; title?: string };
  pad?: {
    horizontal: string;
    vertical: string;
  };
};

const S_RoomHeader = styled.span`
  color: #37003c;
  font-family: Premier League HeavyItalic;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 24px;
  font-weight: bolder;
`;

export const HeaderCard = (props: Props) => {
  const { data, ...rest } = props;
  return (
    <Box
      padding="12px"
      direction="column"
      opacity="true"
      background="#ECECEC"
      height="-webkit-fill-available"
      width="-webkit-fill-available"
      borderRadius="5px"
      flexGrow={1}
    >
      <Box padding="12px 0" direction="column" gapX={10} height="100%">
        {data.title ? (
          <>
            <S_RoomHeader>{data.title}</S_RoomHeader>
            <br />
          </>
        ) : (
          <> </>
        )}
        {data.text ? (
          <Text
            size="large"
            margin={{
              horizontal: 'large',
            }}
            dangerouslySetInnerHTML={{
              __html: data.text,
            }}
            style={{ flexGrow: 1 }}
          ></Text>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
