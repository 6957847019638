import { RawDraftContentState } from 'draft-js';
import { Response } from './enums';

export type Club = {
  _id: string;
  label: string;
  role: string;
  logo: string;
};

export type Question = {
  _id: string;
  html: string;
  value: RawDraftContentState;
  order: number;
  responses: Record<string, Response | null>;
  questionLocked: boolean;
  questionState: number;
};

export type Room = {
  _id: string;
  name: string;
  code: string;
  question: Question[];
  date: Date;
};

export type Answer = {
  value: Response | null;
  color: string;
};

export type User = {
  club: string;
  exp: number;
  iat: number;
  id: string;
  username: string;
};

export type UserData = {
  username: string;
  password: string;
  hashed?: boolean;
};

export enum Role {
  host = 'host',
  club = 'club',
  dashboard = 'dashboard',
}
// store types
export type RoomState = {
  roomId: string;
  currentQuestion: {
    _id: string;
    order: number;
    value: RawDraftContentState;
    questionLocked: boolean;
    responses: Record<string, Response | null>;
    questionState: number;
    html: string;
  };
  roomActive: boolean;
  roomComplete: boolean;
  totalQuestions: number;
  questions: Question[];
  clubResponses: Record<string, Response | null>;
  connectedClubs: Record<string, boolean>;
};

export type AuthState = {
  isAuthenticated: boolean;
  user: User;
};

export type ClubState = {
  id: string;
  role: Role;
  logo?: string;
};

export type ErrorsState = {
  connected: boolean;
  success: boolean;
};

export type AppState = {
  auth: AuthState;
  room: RoomState;
  club: ClubState;
  errors: ErrorsState;
};
