import React, { useState, useEffect } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import axios from 'axios';

import { Button } from 'grommet';

import { setRoomActive, socketUpdateQuestionsOrder } from '../../sockets_client';
import { AppBar, HeaderCard, ClubCard, EditQuestionsOrderPopup } from '../';
import { colours } from '../../App';
import { Edit } from 'grommet-icons';
import { AppState, Club, ClubState, Role, Room, RoomState } from '../../Types';
import { Box } from '../layout/Box';
import { RoomBadge } from '../RoomBadge';
import styled from 'styled-components';

const { REACT_APP_URL } = process.env;

type ConnectedProps = {
  room: RoomState;
  club: ClubState;
};

const RoomPendingStartStaging = ({ room, club }: ConnectedProps) => {
  const [clubs, setClubArray] = useState<Club[]>([]);
  const [roomState, setRoomState] = useState<Room | null>(null);
  const [orderEditorOpen, setOrderEditorOpen] = React.useState(false);
  // QR code
  const [qrCodeValue, setQrCodeValue] = useState('');

  //get clubs
  useEffect(() => {
    axios.get('/club').then((res) => {
      let clubs = res.data
        .sort((a: Club, b: Club) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0))
        .filter((club: Club) => club.role !== 'host');
      setClubArray(clubs);
    });
  }, []);

  //get room data
  useEffect(() => {
    axios.get(`/room/${room.roomId}`).then((res) => {
      setRoomState(res.data);
      setQrCodeValue(`${REACT_APP_URL}/event/${room.roomId}`);
    });
  }, [room.roomId]);

  const onCopyQRCode = () => navigator.clipboard.writeText(qrCodeValue);
  const isHost = club.role === Role.host;

  const clubsList = clubs.map((club) => {
    const isClub = club.role === Role.club;
    const isConnected = room?.connectedClubs[club._id];
    return (
      isClub && (
        <ClubCard
          key={club._id}
          isConnected={isConnected}
          childrenColor={isConnected ? 'status-ok' : 'status-unknown'}
          shouldDisplayQrCode={isHost && !room.roomActive}
          roomId={room.roomId}
          club={club}
          logoSize="medium"
          logo={`/club_logos/400/${club._id}.png`}
        />
      )
    );
  });

  const ClubsGrid = () => (
    <Box
      display="grid"
      margin="10px 0"
      gridTemplateColumns="1fr 1fr 1fr 1fr"
      flexGrow={1}
      gapX={10}
      gapY={10}
    >
      {clubsList}
    </Box>
  );

  return (
    <Box display="flex" direction="column" height="100vh">
      <Box
        display="flex"
        direction="row"
        justify="space-between"
        gapX={10}
        padding="10px 0"
        minHeight="0"
        flexGrow={1}
      >
        <AppBar side qrCodeValue={qrCodeValue} onCopyQRCode={onCopyQRCode} />
        <Box
          display="flex"
          direction="column"
          flexGrow={1}
          zIndex="1"
          padding="10px"
          borderRadius="5px"
          overflowVertical="scroll"
        >
          <HeaderCard
            data={{
              title: roomState?.name,
              text: 'Waiting for the host to start the event.',
            }}
          />
        </Box>

        {isHost && (
          <Box
            display="flex"
            width="200px"
            padding="10px"
            justify="space-evenly"
            alignItems="center"
            direction="column"
            gapX={10}
            flexShrink={1}
          >
            <RoomBadge roomId={room.roomId} />
            <Button
              onClick={() => setOrderEditorOpen(!orderEditorOpen)}
              style={{
                borderRadius: '5px',
                padding: '20px 10px',
                width: '100%',
                fontWeight: 'bolder',
                color: colours.plBrightPurple,
                backgroundColor: colours.plLightPurple,
                border: 'none',
              }}
              label={
                <>
                  <Edit size="small" style={{ padding: '0 10px' }} color={colours.plBrightPurple} />
                  Edit resolutions
                </>
              }
            />
            {isHost && (
              <Button
                primary
                style={{
                  borderRadius: '5px',
                  padding: '20px 10px',
                  width: '100%',
                  fontWeight: 'bolder',
                }}
                onClick={() => {
                  setRoomActive(room.roomId);
                }}
                label="Activate Event"
              />
            )}
            {orderEditorOpen && (
              <EditQuestionsOrderPopup
                broadcast={() => {
                  socketUpdateQuestionsOrder(room.roomId, clubs);
                }}
                onClose={() => {
                  setOrderEditorOpen(false);
                }}
              />
            )}
          </Box>
        )}
      </Box>
      <ClubsGrid />
    </Box>
  );
};

const mapStateToProps: MapStateToProps<ConnectedProps, {}, AppState> = (state: AppState) => ({
  room: state.room,
  club: state.club,
});

export const RoomPendingStart = connect(mapStateToProps)(RoomPendingStartStaging);
