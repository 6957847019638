import React from 'react';

import { AppBar, HeaderCard } from '../';
import { Box } from '../layout/Box';
import styled from 'styled-components';

const S_WelcomeBox = styled.div`
  border-radius: 5px;
  background-color: #ececec;
  flex-direction: column;
  flex-grow: 1;
  padding: 50px 20px;
  text-align: center;
`;

export const RoomLanding = () => (
  <Box padding="50px">
    <AppBar full />
    <Box overflow="auto" flexGrow={1} padding="96px 24px">
      <S_WelcomeBox>Welcome to the Shareholder&apos;s Meeting.</S_WelcomeBox>
    </Box>
  </Box>
);
