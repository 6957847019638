import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Grommet } from 'grommet';
import { FormAdd, FormSubtract, FormLock } from 'grommet-icons';

import { Home, Join, Event, Login, Create } from './pages';

import PrivateRoute from './components/PrivateRoute';
import { Box } from './components/layout/Box';

export const colours = {
  plGreen: '#00ff85',
  plPurple: '#38003c',
  plBrightPurple: '#7D4CDB',
  plMagenta: '#e90052',
  plGrey: '#766c6c',
  plLightBlue: '#04f5ff',
  plLightGrey: '#F9F9F9',
  plLightPurple: '#EBE6EC',
  plDarkPurple: '#37003C',
  plDarkGrey: '#545454',
  plDarkGreen: '#006F3B',
  plWhite: '#ffffff',
  plStone: '#ebebe6',
};
const theme = {
  global: {
    font: {
      family: 'Premier League',
      size: '18px',
      height: '20px',
    },
    breakpoints: {
      small: {
        value: 700,
      },
      medium: {
        value: 1000,
      },
      large: {
        value: 1500,
      },
      xlarge: {
        value: 3000,
      },
    },
  },
  formField: {
    label: {
      requiredIndicator: true,
    },
  },
  accordion: {
    icons: {
      collapse: FormSubtract,
      expand: FormAdd,
    },
  },
  checkBox: {
    icon: {
      size: '18px',
      extend: 'stroke: white;',
    },
    icons: {
      checked: FormLock,
    },
  },
};

const App = () => (
  <Router>
    <Grommet
      theme={theme}
      full
      style={{ minHeight: '-webkit-fill-available', maxHeight: '-webkit-fill-available' }}
    >
      <Box display="flex" direction="column" minHeight="100%">
        <Switch>
          <PrivateRoute path="/home" component={Home} />
          <PrivateRoute path="/join" component={Join} />
          <PrivateRoute path="/create" component={Create} />
          <PrivateRoute path="/event/:id" component={Event} />
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </Box>
    </Grommet>
  </Router>
);

export default App;
