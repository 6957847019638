import React, { useEffect, useState } from 'react';
import { Layer, Text } from 'grommet';
import { colours } from '../App';
import { MeetingRoom } from '../assets/MeetingRoom';
import QRCode from 'react-qr-code';
import { Box } from './layout/Box';
import { appUrl } from '../config';

const REACT_APP_URL = appUrl;

type Props = {
  roomId: string;
};
export const RoomBadge = ({ roomId }: Props) => {
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [showQrCode, setShowQrCode] = useState(false);

  //get room data
  useEffect(() => {
    setQrCodeValue(`${REACT_APP_URL}/event/${roomId}`);
  }, [roomId]);

  return (
    <div style={{ width: '100%' }}>
      <Box
        display="flex"
        background={colours.plDarkPurple}
        color="white"
        direction="row"
        alignItems="center"
        justify="space-between"
        borderRadius="5px"
        padding="10px 30px"
        maxWidth="60%"
      >
        <MeetingRoom color="white" />
        <Text weight="bold" size="large">
          {roomId}
        </Text>
      </Box>
      {showQrCode && qrCodeValue && (
        <Layer onEsc={() => setShowQrCode(false)} onClickOutside={() => setShowQrCode(false)}>
          <Box display="flex" padding="50px" direction="column" alignItems="center">
            <QRCode
              title={qrCodeValue}
              value={qrCodeValue}
              bgColor={'#FFFFFF'}
              fgColor={'#000000'}
              size={200}
              label="close"
              onClick={() => setShowQrCode(false)}
            />
          </Box>
        </Layer>
      )}
    </div>
  );
};
