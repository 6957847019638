import React from 'react';

export const Left = () => (
  <svg width="48" height="36" viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.4C0 4.15979 0 3.03968 0.435974 2.18404C0.819467 1.43139 1.43139 0.819467 2.18404 0.435974C3.03968 0 4.15979 0 6.4 0H41.6C43.8402 0 44.9603 0 45.816 0.435974C46.5686 0.819467 47.1805 1.43139 47.564 2.18404C48 3.03968 48 4.15979 48 6.4V29.6C48 31.8402 48 32.9603 47.564 33.816C47.1805 34.5686 46.5686 35.1805 45.816 35.564C44.9603 36 43.8402 36 41.6 36H6.4C4.15979 36 3.03968 36 2.18404 35.564C1.43139 35.1805 0.819467 34.5686 0.435974 33.816C0 32.9603 0 31.8402 0 29.6V6.4Z"
      fill="#7D4CDB"
      fillOpacity="0.1"
    />
    <path d="M28 11L20 19L28 27" stroke="#7D4CDB" strokeWidth="2" />
  </svg>
);
