import { SET_ROOM_CODE } from '../actions/index';
import {
  SET_CURRENT_QUESTION,
  SET_ROOM_ACTIVE,
  SET_ROOM_TOTAL_QUESTIONS,
  SET_ROOM_COMPLETE,
  SET_QUESTIONS,
  SET_CONNECTED_CLUBS,
} from '../sockets_client';
import { Question, RoomState } from '../Types';

export default function roomReducer(
  state: RoomState = {
    roomActive: false,
    roomComplete: false,
    roomId: '',
    totalQuestions: 0,
    currentQuestion: {} as Question,
    questions: [],
    clubResponses: {},
    connectedClubs: {},
  },
  action: any
) {
  switch (action.type) {
    case SET_ROOM_ACTIVE:
      return {
        ...state,
        roomActive: action.payload,
      };
    case SET_ROOM_COMPLETE:
      return {
        ...state,
        roomComplete: action.payload,
      };
    case SET_ROOM_CODE:
      return {
        ...state,
        roomId: action.payload,
      };
    case SET_ROOM_TOTAL_QUESTIONS:
      return {
        ...state,
        totalQuestions: action.payload,
      };
    case SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.payload,
      };
    case SET_QUESTIONS:
      return {
        ...state,
        questions: action.payload.questions,
        currentQuestion: action.payload.currentQuestion,
        totalQuestions: action.payload.questions.length,
      };
    case SET_CONNECTED_CLUBS:
      return {
        ...state,
        connectedClubs: action.payload,
      };
    default:
      return state;
  }
}
