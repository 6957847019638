import React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import axios from 'axios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Button, Layer, Heading } from 'grommet';
import { Close } from 'grommet-icons';
import { Questions } from '../../pages';
import { AppState, Question, RoomState } from '../../Types';
import { Box } from '../layout/Box';

type ConnectedProps = {
  room: RoomState;
};

type EditQuestionsOrderPopupStagingProps = {
  onClose: () => void;
  broadcast: () => void;
};

type Props = ConnectedProps & EditQuestionsOrderPopupStagingProps;

const EditQuestionsOrderPopupStaging = ({ onClose, broadcast, room }: Props) => {
  const roomId = room.roomId;

  const submitNewOrder = (newOrderedQuestions: Question[]) => {
    let outputQuestions = [];
    for (let i = 0; i < newOrderedQuestions.length; i++) {
      outputQuestions.push({
        _id: newOrderedQuestions[i]._id,
        value: newOrderedQuestions[i].value,
        html: newOrderedQuestions[i].html,
        order: i + 1,
      });
    }

    axios
      .post(`/room/${roomId}`, {
        roomId: roomId,
        questions: outputQuestions,
      })
      .then(() => {
        broadcast();
        onClose();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Layer position="right" full="vertical" modal onClickOutside={onClose} onEsc={onClose}>
      <Box overflow="auto" width="700px" padding="40px">
        <Box display="flex" direction="row" justify="space-between">
          <Heading level={2} margin="none">
            Edit All Resolutions
          </Heading>
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        <Box flexGrow={1} overflow="auto">
          <Questions
            questionsArr={room.questions}
            isCreatingEvent={false}
            submitForm={submitNewOrder}
          />
        </Box>
      </Box>
    </Layer>
  );
};

const mapStateToProps: MapStateToProps<
  ConnectedProps,
  EditQuestionsOrderPopupStagingProps,
  AppState
> = (state: AppState) => ({
  room: state.room,
});

export const EditQuestionsOrderPopup = connect(mapStateToProps)(EditQuestionsOrderPopupStaging);
