import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { connect, MapStateToProps } from 'react-redux';

import { socketClubConnected } from '../sockets_client';

import {
  RoomComplete,
  RoomHostPanel,
  RoomLanding,
  RoomPendingStart,
  RoomQuestion,
} from '../components';
import { ReconnectingPopup } from '../components/ReconnectingPopup';
import { AppState, ClubState, ErrorsState, Role, RoomState } from '../Types';

type ConnectedProps = {
  room: RoomState;
  club: ClubState;
  errors: ErrorsState;
};

const EventStaging = ({ room, club, errors }: ConnectedProps) => {
  const [disconnectedError, setDisconnectedError] = useState(false);
  const history = useHistory();

  if (room.roomId === '') {
    history.push('/join');
  }

  //alert server club has connected
  useEffect(() => {
    if (room.roomId !== '') {
      socketClubConnected(room.roomId, club.id);
    }
  }, [room.roomId, club.id]);

  useEffect(() => {
    if (!errors) return;
    if (errors.connected !== null && !errors.connected) {
      setDisconnectedError(true);
    }
  }, [errors.connected]);

  const isRoomActive = room.roomActive;
  const isHostOrDashboard = club.role !== Role.club;
  const isRoomComplete = room.roomComplete;

  const renderPage = () => {
    // if Room is inactive
    if (!isRoomActive) {
      return isHostOrDashboard ? <RoomPendingStart /> : <RoomLanding />;
    }

    // if Room is complete
    if (isRoomComplete) {
      return <RoomComplete />;
    } else {
      return isHostOrDashboard ? <RoomHostPanel /> : <RoomQuestion />;
    }
  };

  return (
    <>
      {!!disconnectedError && <ReconnectingPopup />}
      {renderPage()}
    </>
  );
};

const mapStateToProps: MapStateToProps<ConnectedProps, {}, AppState> = (state: AppState) => ({
  club: state.club,
  room: state.room,
  errors: state.errors,
});

export const Event = connect(mapStateToProps)(EventStaging);
