import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { connect, MapStateToProps } from 'react-redux';
import queryString from 'query-string';

import { Button, FormField, TextInput } from 'grommet';

import { loginUser, logoutUser } from '../actions/';
import isEmpty from 'is-empty';
import { AppState, AuthState, ErrorsState, UserData } from '../Types';
import { Box } from '../components/layout/Box';

type ConnectedProps = {
  auth: AuthState;
  errors: ErrorsState;
};

type DispatchProps = {
  loginUser: ({ username, password, hashed }: UserData) => void;
  logoutUser: () => void;
};

type Props = ConnectedProps & DispatchProps;

const LoginStaging = ({ auth, errors, loginUser, logoutUser }: Props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [roomError, setRoomError] = useState({
    username: '',
    password: '',
  });

  const history = useHistory();
  const location = useLocation();

  const manageLogin = async () => {
    if (auth.isAuthenticated && !location.search) {
      history.push('/home');
      return;
    }

    let params = queryString.parse(location.search);
    const { username, password, roomId, hashed } = params;
    //user is scanning a QR code they aren't logged into. Log them out and restart
    if (auth.isAuthenticated && auth?.user?.username !== username) {
      await logoutUser();
      window.location.reload();
    }

    // If logged in and being redirected from QR code, should redirect them to room
    if (auth.isAuthenticated && location.search && roomId) {
      history.push('/join', { roomId });
    }

    // If logged in and being redirected from QR code, should redirect them to room
    if (!auth.isAuthenticated && !!location.search && !!username && !!password) {
      loginUser({
        // @ts-ignore
        username,
        // @ts-ignore
        password,
        // @ts-ignore
        hashed,
      });
    }
  };

  // @ts-expect-error TS(2345): Argument of type '() => Promise<void>' is not assi... Remove this comment to see the full error message
  useEffect(async () => {
    await manageLogin();
  }, [auth.isAuthenticated, location.search]);

  const onSubmit = () => {
    if (isEmpty(username)) {
      setRoomError({
        username: 'No username provided',
        password: '',
      });
      return;
    }

    if (isEmpty(password)) {
      setRoomError({
        username: '',
        password: 'No password provided',
      });
      return;
    }
    loginUser({
      // @ts-ignore
      username,
      // @ts-ignore
      password,
    });
  };

  useEffect(() => {
    if (!errors) return;
    if (!errors.connected) {
      setRoomError({
        username: '',
        password: 'Unable to connect to the server',
      });
      return;
    }
    //TODO fix the errors type... we need to acess errors.errors.success for the below to work, doesn't make sense
    if (errors?.success === false) {
      setRoomError({
        username: '',
        password: 'Incorrect password',
      });
      return;
    }
  }, [errors]);

  return (
    <Box display="flex" direction="column" padding="100px" alignItems="center">
      <Box display="flex" direction="column" padding="80px" width="350px" gapY={20}>
        <FormField htmlFor="username" label="Username" error={roomError.username}>
          <TextInput id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
        </FormField>
        <FormField htmlFor="password" label="Password" error={roomError.password}>
          <TextInput
            id="room-title"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormField>
        <Box display="flex" direction="row" margin="30px 0 0 0 ">
          <Button
            style={{ borderRadius: '5px' }}
            primary
            label="Login"
            onClick={() => onSubmit()}
          />
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps: MapStateToProps<ConnectedProps, {}, AppState> = (state: AppState) => ({
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = {
  loginUser,
  logoutUser,
};

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginStaging);
