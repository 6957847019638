import React, { useState } from 'react';

import { Avatar, Card, Layer, Text } from 'grommet';
import { colours } from '../App';
import { QrCodeIcon } from '../assets/QrCodeIcon';
import QRCode from 'react-qr-code';
import axios from 'axios';
import { Wifi } from 'grommet-icons';
import { Club, User } from '../Types';
import { Box } from './layout/Box';
import { appUrl } from '../config';

const REACT_APP_URL = appUrl;

type IdentifierProps = {
  children?: JSX.Element | JSX.Element[];
  fontColour?: string;
  title?: string;
  direction?: 'row' | 'column' | 'row-responsive' | 'row-reverse' | 'column-reverse';
  logoSize?: string;
  subTitle?: string;
  pad?: string;
  logo: string;
  size: string;
  alignTextToCenter?: boolean;
  flex: 'grow' | 'shrink';
};
const Identifier = ({
  children,
  fontColour,
  title,
  direction = 'row',
  logoSize = 'small',
  subTitle,
  logo,
  pad,
  size,
  flex,
  alignTextToCenter,
  ...rest
}: IdentifierProps) => (
  <Box
    display="flex"
    gapY={10}
    alignItems="center"
    alignSelf="center"
    flexGrow={1}
    direction={direction}
    padding="0 6px"
    maxHeight="60px"
    {...rest}
  >
    {logo ? <Avatar size={logoSize} src={logo} round={false} /> : <></>}
    {title || subTitle ? (
      <Box display="flex" direction="row" gapX={6} alignItems="center" justify="space-between">
        <Text
          style={{
            whiteSpace: 'unset',
            overflow: 'hidden',
            transition: 'all 0.5s ease',
            textAlign: alignTextToCenter ? 'center' : undefined,
          }}
          size={size}
          weight="bold"
          color={fontColour || colours.plDarkGrey}
        >
          {title}
        </Text>
        {subTitle ? <Text size="xsmall">[{subTitle}]</Text> : <> </>}
      </Box>
    ) : (
      <></>
    )}
  </Box>
);

type ClubCardProps = {
  color?: string;
  fontColour?: string;
  childrenColor?: string;
  children?: JSX.Element | JSX.Element[];
  onClick?: () => void;
  logo: string;
  club?: Club;
  title?: string;
  subtitle?: string;
  roomId?: string;
  logoSize?: string;
  isConnected?: boolean;
  shouldDisplayQrCode?: boolean;
};

export const ClubCard = (props: ClubCardProps) => {
  const {
    color,
    fontColour,
    childrenColor,
    children,
    onClick,
    logo,
    club,
    title,
    subtitle,
    roomId,
    logoSize,
    isConnected,
    shouldDisplayQrCode,
    ...rest
  } = props;
  const [showQrCode, setShowQrCode] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState('');

  const generateClubsQRCode = () => {
    // api call to fetch username and password of the club by ID (key)
    if (club?._id) {
      try {
        axios.get(`/user`).then((res) => {
          const { data } = res;
          const user = data.find((user: User) => user.club === club._id);
          if (!!user) {
            try {
              const clubUsername = user.username;
              const clubHashedPassword = user.passwordHash;

              const qrCodeUrl = `${REACT_APP_URL}/login?username=${clubUsername}&password=${clubHashedPassword}&roomId=${roomId}&hashed=true`;
              setQrCodeValue(qrCodeUrl);
              setShowQrCode(true);
              // build QR code link containing url + query params like
            } catch (err) {
              return 'error occurred';
            }
          }
        });
      } catch (err) {
        return 'error occurred';
      }
    }
  };

  return (
    <Card
      key={club?._id}
      onClick={onClick}
      style={{ transition: 'all 0.5s ease', display: 'flex' }}
      background={color ? color : 'transparent'}
      direction="column"
      justify="between"
    >
      <Box display="flex" direction="row" justify="space-evenly" flexGrow={1} padding="0 0 0 5px">
        <Identifier
          title={club?.label}
          subTitle={subtitle}
          size="medium"
          flex="grow"
          pad="6px 12px"
          logo={logo}
          fontColour={fontColour}
          logoSize={logoSize}
        />
        {shouldDisplayQrCode && (
          <Box
            display="flex"
            alignItems="center"
            onClick={() => setShowQrCode(true)}
            background={colours.plLightPurple}
            height="inherit"
            minWidth="fit-content"
            justify="center"
            boxShadow="none"
          >
            <Box maxWidth="60px" cursor="pointer" padding="0 10px" onClick={generateClubsQRCode}>
              <QrCodeIcon />
            </Box>
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justify="flex-start"
        padding="5px 15px"
        opacity="0.8"
        background={colours.plLightGrey}
        flexGrow={1}
      >
        <Box display="flex" direction="row">
          {
            <Box display="flex" direction="row">
              <Wifi size="medium" color={isConnected ? colours.plGreen : colours.plMagenta} />
              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: '14px',
                  opacity: 0.8,
                  color: '#151515',
                }}
                size="medium"
              >
                {!isConnected ? 'Disconnected' : 'Connected'}
              </Text>
            </Box>
          }
        </Box>
      </Box>

      {showQrCode && (
        <Layer onEsc={() => setShowQrCode(false)} onClickOutside={() => setShowQrCode(false)}>
          <Box display="flex" padding="50px" direction="column" alignItems="center">
            <Identifier
              alignTextToCenter
              direction="column"
              title={club?.label}
              subTitle={subtitle}
              size="xlarge"
              logoSize="xlarge"
              flex="grow"
              pad="12px 6px 12px 12px;"
              logo={`/club_logos/400/${club?._id}.png`}
            />
            <QRCode
              title={qrCodeValue}
              value={qrCodeValue}
              bgColor={'#FFFFFF'}
              fgColor={'#000000'}
              size={200}
              label="close"
              onClick={() => setShowQrCode(false)}
            />
          </Box>
        </Layer>
      )}
    </Card>
  );
};
