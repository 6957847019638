import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { connect, MapStateToProps } from 'react-redux';
import axios from 'axios';
import { EditorState, convertToRaw, convertFromRaw, RawDraftContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Button, FormField, TextInput, Text, Paragraph, Stack } from 'grommet';
import { Add, CaretDownFill, CaretUpFill, Drag, Save } from 'grommet-icons';

import { AppBar } from '../components/';
import { setUserRoom } from '../actions/';

import { motion, useMotionValue } from 'framer-motion';
import { findIndex } from './utils';
import move from 'array-move';
import isEmpty from 'is-empty';
import { AppState, Question, ClubState, RoomState } from '../Types';
import { Box } from '../components/layout/Box';

// Spring configs
const onTop = {
  zIndex: 1,
};
const flat = {
  zIndex: 0,
  transition: {
    delay: 0.3,
  },
};

type MyEditorProps = {
  initValue: any;
  onChange: (output: RawDraftContentState, htmlState: string, index: number) => void;
  index: number;
  totalQuestionsCount: number;
  room?: RoomState;
};
const MyEditor = ({ initValue, onChange, index, totalQuestionsCount, room }: MyEditorProps) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [htmlState, setHtmlState] = useState('');

  useEffect(() => {
    const isSingleQuestionEditing = room?.currentQuestion?.value;

    if (isSingleQuestionEditing) {
      const questionLabel = room.currentQuestion.value;
      if (!isEmpty(questionLabel)) {
        if (isEmpty(questionLabel.entityMap)) {
          questionLabel.entityMap = {};
        }
        const contentState = convertFromRaw(questionLabel);
        setEditorState(EditorState.createWithContent(contentState));
      }
    } else {
      const questionLabel = initValue;
      if (!isEmpty(questionLabel)) {
        if (isEmpty(questionLabel.entityMap)) {
          questionLabel.entityMap = {};
        }
        const contentState = convertFromRaw(questionLabel);
        setEditorState(EditorState.createWithContent(contentState));
      } else {
        setEditorState(EditorState.createEmpty());
      }
    }
  }, [room?.currentQuestion, totalQuestionsCount]);

  const onChangeEditor = (e: EditorState) => {
    let output = convertToRaw(editorState.getCurrentContent());
    setEditorState(e);

    let htmlOutput = stateToHTML(editorState.getCurrentContent());
    setHtmlState(htmlOutput);

    onChange(output, htmlState, index);
  };

  return (
    editorState && (
      <Editor
        editorState={editorState}
        onEditorStateChange={onChangeEditor}
        editorStyle={{
          border: '1px solid #F1F1F1',
          height: '150px',
          minHeight: '150px',
          padding: '0 10px',
          backgroundColor: 'white',
        }}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'textAlign', 'colorPicker', 'list'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
          },
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4'],
          },
          fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
          },
          colorPicker: {
            colors: [
              'rgb(97,189,109)',
              'rgb(26,188,156)',
              'rgb(84,172,210)',
              'rgb(44,130,201)',
              'rgb(147,101,184)',
              'rgb(71,85,119)',
              'rgb(204,204,204)',
              'rgb(65,168,95)',
              'rgb(0,168,133)',
              'rgb(61,142,185)',
              'rgb(41,105,176)',
              'rgb(85,57,130)',
              'rgb(40,50,78)',
              'rgb(0,0,0)',
              'rgb(247,218,100)',
              'rgb(251,160,38)',
              'rgb(235,107,86)',
              'rgb(226,80,65)',
              'rgb(163,143,132)',
              'rgb(239,239,239)',
              'rgb(255,255,255)',
              'rgb(250,197,28)',
              'rgb(243,121,52)',
              'rgb(209,72,65)',
              'rgb(184,49,47)',
              'rgb(124,112,107)',
              'rgb(209,213,216)',
            ],
          },
          list: {
            inDropdown: true,
          },
          textAlign: {
            inDropdown: true,
          },
        }}
      />
    )
  );
};

type DraggableItemProps = {
  setPosition: (i: number, { height, top }: Position) => void;
  moveItem: (i: number, dragOffset: number) => void;
  i: number;
  question: Question;
  editorOnChange: (rawContent: RawDraftContentState, htmlOutput: string, index: number) => void;
  totalQuestionsCount: number;
  deleteQuestion: (index: number) => void;
};

const DraggableItem = ({
  setPosition,
  moveItem,
  i,
  question,
  editorOnChange,
  totalQuestionsCount,
  deleteQuestion,
}: DraggableItemProps) => {
  const [isDragging, setDragging] = useState(false);
  const [expanded, setExpanded] = useState(true);

  // We'll use a `ref` to access the DOM element that the `motion.li` produces.
  // This will allow us to measure its height and position, which will be useful to
  // decide when a dragging element should switch places with its siblings.
  const ref = useRef<HTMLLIElement>(null);

  // By manually creating a reference to `dragOriginY` we can manipulate this value
  // if the user is dragging this DOM element while the drag gesture is active to
  // compensate for any movement as the items are re-positioned.
  const dragOriginY = useMotionValue(0);

  // Update the measured position of the item so we can calculate when we should rearrange.
  useEffect(() => {
    setPosition(i, {
      height: ref?.current?.offsetHeight,
      top: ref?.current?.offsetTop,
    });
  });

  return (
    <motion.li
      ref={ref}
      initial={false}
      // If we're dragging, we want to set the zIndex of that item to be on top of the other items.
      animate={isDragging ? onTop : flat}
      drag="y"
      style={{
        marginBottom: '30px',
        borderBottom: '1px solid grey',
        backgroundColor: 'white',
      }}
      dragOriginY={dragOriginY}
      dragConstraints={{
        top: 0,
        bottom: 0,
      }}
      dragElastic={1}
      onDragStart={() => setDragging(true)}
      onDragEnd={() => setDragging(false)}
      onDrag={(e, { point }) => moveItem(i, point.y)}
      positionTransition={({ delta }) => {
        if (isDragging) {
          // If we're dragging, we want to "undo" the items movement within the list
          // by manipulating its dragOriginY. This will keep the item under the cursor,
          // even though it's jumping around the DOM.
          dragOriginY.set(dragOriginY.get() + delta.y);
        }

        // If `positionTransition` is a function and returns `false`, it's telling
        // Motion not to animate from its old position into its new one. If we're
        // dragging, we don't want any animation to occur.
        return !isDragging;
      }}
    >
      <Box
        display="flex"
        direction="row"
        justify="space-between"
        padding="0 0 10px 0"
        cursor="move"
      >
        <Box display="flex" direction="row" justify="flex-start">
          <Drag
            style={{
              paddingRight: 10,
            }}
          />
          <Text weight="bold">Resolution {i + 1}</Text>
        </Box>
        <Box cursor="pointer" display="flex" onClick={() => setExpanded(!expanded)}>
          {expanded ? <CaretUpFill /> : <CaretDownFill />}
        </Box>
      </Box>
      <Box
        borderBottom="1px solid grey"
        padding="10px 0"
        background="ghost"
        display={expanded ? 'block' : 'none'}
        position={expanded ? 'static' : 'absolute'}
        opacity={expanded ? '1' : '0'}
        transition="opacity 1s linear"
      >
        <MyEditor
          initValue={question?.value}
          onChange={editorOnChange}
          index={i}
          totalQuestionsCount={totalQuestionsCount}
        />
        <Box padding="12px" alignSelf="center" display="flex" justify="center">
          <Button
            style={{
              backgroundColor: 'white',
            }}
            color="red"
            plain
            label="Delete Resolution"
            disabled={totalQuestionsCount <= 1}
            onClick={() => deleteQuestion(i)}
          />
        </Box>
      </Box>
    </motion.li>
  );
};

export type Position = { height?: number; top?: number };

type QuestionsProps = {
  submitForm: (questionsArr: Question[]) => void;
  questionsArr?: Question[];
  isCreatingEvent: boolean;
};
export const Questions = ({ submitForm, questionsArr, isCreatingEvent }: QuestionsProps) => {
  const history = useHistory();
  const [initialQuestions, setInitialQuestions] = useState<Question[]>([]);

  const questions = [
    {
      _id: '',
      value: '' as unknown as RawDraftContentState,
      html: '',
      order: 1,
    },
  ];

  useEffect(() => {
    if (questionsArr) {
      setInitialQuestions(questionsArr);
    } else {
      setInitialQuestions(questions as Question[]);
    }
  }, [questionsArr]);

  // We need to collect an array of position data for all of this component's
  // `DraggableItem` children, so we can later use that in calculations to decide when a dragging
  // `DraggableItem` should swap places with its siblings.
  const positions = useRef([]).current as Position[];
  const setPosition = (i: number, offset: Position) => (positions[i] = offset);

  // Find the ideal index for a dragging item based on its position in the array, and its
  // current drag offset. If it's different to its current index, we swap this item with that
  // sibling.
  const moveItem = (i: number, dragOffset: number) => {
    const targetIndex = findIndex(i, dragOffset, positions);
    if (targetIndex !== i) setInitialQuestions(move(initialQuestions, i, targetIndex));
  };

  const addQuestion = () => {
    setInitialQuestions([
      ...initialQuestions,
      { _id: '', value: '', html: '', order: initialQuestions.length + 1 } as unknown as Question,
    ]);
  };

  const deleteQuestion = (index: number) => {
    if (initialQuestions.length <= 1) {
      return;
    }

    const questions = [...initialQuestions];
    questions.splice(index, 1);

    const reorderedQuestions = questions.map((question, index) => ({
      ...question,
      order: index + 1,
    }));

    setInitialQuestions(reorderedQuestions);
  };

  const editorOnChange = (rawContent: RawDraftContentState, htmlOutput: string, index: number) => {
    let newArr = [...initialQuestions];
    newArr[index].value = rawContent;
    newArr[index].html = htmlOutput;
    setInitialQuestions(newArr);
  };

  return (
    <Box margin="20px 0">
      <ul
        style={{
          listStyle: 'none',
          paddingLeft: 0,
        }}
      >
        {initialQuestions.map((question, i) => (
          <DraggableItem
            key={question.order}
            i={i}
            setPosition={setPosition}
            moveItem={moveItem}
            editorOnChange={editorOnChange}
            question={question}
            totalQuestionsCount={initialQuestions.length}
            deleteQuestion={deleteQuestion}
          />
        ))}
      </ul>
      {isCreatingEvent ? (
        <Box display="flex" direction="row" justify="space-between" margin={'20px 0 0 0'}>
          <Button style={{ borderRadius: '5px' }} label="Cancel" onClick={() => history.goBack()} />
          <Button
            style={{ borderRadius: '5px' }}
            icon={<Add />}
            label="Add Resolution"
            onClick={() => addQuestion()}
          />
          <Button
            style={{ borderRadius: '5px' }}
            primary
            label="Create Event"
            onClick={() => submitForm(initialQuestions)}
          />
        </Box>
      ) : (
        <Box display="flex" direction="row" justify="space-between" margin={'20px 0 0 0'}>
          <Button
            icon={<Add />}
            style={{ borderRadius: '5px' }}
            label="Add Resolution"
            onClick={() => addQuestion()}
          />
          <Button
            type="button"
            style={{ borderRadius: '5px' }}
            onClick={() => submitForm(initialQuestions)}
            label="Save Resolutions"
            icon={<Save />}
            primary
          />
        </Box>
      )}
    </Box>
  );
};

type ConnectedProps = {
  club: ClubState;
};

const CreateStaging = (props: ConnectedProps) => {
  const history = useHistory();
  const [roomId, setRoomId] = useState('');
  const [roomName, setRoomName] = useState('');

  const [roomError, setRoomError] = useState('');

  const generateNewRoomID = () => {
    axios.get(`/room/newRoomId`).then((res) => {
      setRoomId(res.data.code);
    });
  };

  useEffect(() => {
    generateNewRoomID();
  }, []);

  const submitForm = (initialQuestions: Question[]) => {
    let outputQuestions = [];
    for (let i = 0; i < initialQuestions.length; i++) {
      outputQuestions.push({
        value: initialQuestions[i].value,
        html: initialQuestions[i].html,
        order: i + 1,
      });
    }
    axios
      .post(`/room`, {
        name: roomName,
        code: roomId,
        questions: outputQuestions,
      })
      .then(() => {
        localStorage.setItem('roomId', roomId);
        history.push('/join');
      })
      .catch((err) => setRoomError(err));
  };

  return (
    <>
      <AppBar full />
      <Box display="flex" justify="center">
        <Box padding="48px" width="700px">
          <Box padding="24px 0" display="flex" direction="column">
            <Box display="flex" direction="row" alignItems="center" gapX={10}>
              <Text margin="none">Event ID:</Text>
              <Button
                color="accent-2"
                label={roomId}
                onClick={() => {
                  generateNewRoomID();
                }}
                {...props}
              />
            </Box>
            <Paragraph fill size="small">
              Please make a note of the event ID as it will be needed for guests to join the event
              later.
            </Paragraph>
          </Box>
          <FormField error={roomError}>
            <TextInput
              id="event-title"
              placeholder="Title of the event"
              value={roomName}
              onChange={(e) => setRoomName(e.target.value)}
            />
          </FormField>
          <Questions submitForm={submitForm} isCreatingEvent />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps: MapStateToProps<ConnectedProps, {}, AppState> = (state: AppState) => ({
  club: state.club,
});

const mapDispatchToProps = {
  setUserRoom,
};

export const Create = connect(mapStateToProps, mapDispatchToProps)(CreateStaging);
