import React, { useState, useEffect } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import isEmpty from 'is-empty';
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import { Button, Heading, ResponsiveContext, Text } from 'grommet';
import { Lock, Undo, Unlock } from 'grommet-icons';

import { socketClubResponse } from '../../sockets_client';
import { AppBar, AnswerCard, QuestionTitle } from '../';
import { colours } from '../../App';
import { RoomBadge } from '../RoomBadge';
import { Answer, AppState, ClubState, Question, RoomState } from '../../Types';
import { Size } from '../../enums';
import { Box } from '../layout/Box';
import styled from 'styled-components';

type ConnectedProps = {
  room: RoomState;
  club: ClubState;
};

const S_RoomHeader = styled.span`
  color: #37003c;
  font-family: Premier League HeavyItalic;
  text-transform: uppercase;
  line-height: 24px;
  font-weight: bolder;
`;

const RoomQuestionStaging = ({ room, club }: ConnectedProps) => {
  const [answer, setAnswer] = useState<Answer | null>(null);
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [answerConfirmed, setAnswerConfirmed] = useState(false);
  const [questionDisplay, setQuestionDisplay] = useState('');

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const answerToColour: Record<string, string> = {
    for: colours.plGreen,
    against: colours.plBrightPurple,
    abstain: colours.plGrey,
  };

  const answerToFontColour: { for: string; against: string; abstain: string } = {
    for: colours.plDarkPurple,
    against: colours.plStone,
    abstain: colours.plStone,
    // '': colours.plDarkPurple,
  };

  const initAnswerArray = () =>
    Object.keys(answerToColour).map((answer) => ({
      color: answerToColour[answer as keyof typeof answerToColour],
      value: answer,
    }));

  const renderAnswers = (questionLocked: boolean, fontSize: number, size: Size) =>
    initAnswerArray().map((answer, i) => (
      <AnswerCard
        size={size}
        text={answer.value.toUpperCase()}
        fontSize={fontSize}
        color={answer.color}
        key={i}
        disabled={questionLocked}
        onClick={() => setAnswer(answer as Answer)}
      />
    ));

  const broadcastAnswer = (value: Response | null) => {
    socketClubResponse(room.roomId, club.id, value);
  };

  const confirmAnswer = (answer: Answer | null) => {
    if (answer) {
      setAnswerConfirmed(true);
      setBackgroundColor(answer.color);
      broadcastAnswer(answer.value as unknown as Response);
    }
  };

  const clearAnswer = () => {
    setAnswer(null);
    setAnswerConfirmed(false);
    setBackgroundColor('transparent');
    broadcastAnswer(null);
  };

  const initQuestion = (question: Question) => {
    if (
      club.id &&
      question &&
      question.responses &&
      question.responses[club.id] &&
      !!question.responses[club.id]
    ) {
      setAnswer({
        color: answerToColour[question.responses[club.id] as keyof typeof answerToColour],
        value: question.responses[club.id],
      });
      setAnswerConfirmed(true);
      setBackgroundColor(
        answerToColour[question.responses[club.id] as keyof typeof answerToColour]
      );
    } else {
      setAnswer(null);
      setAnswerConfirmed(false);
      setBackgroundColor('transparent');
    }
  };

  useEffect(() => {
    const { responses } = room.currentQuestion;
    if (!responses || !responses[club.id]) return;
    if (responses[club.id] !== answer?.value) {
      initQuestion(room.currentQuestion);
    }
  }, [room.currentQuestion.responses]);

  useEffect(() => {
    initQuestion(room.currentQuestion);
  }, [room.currentQuestion._id]);

  //when question state changes
  useEffect(() => {
    if (room.currentQuestion.questionState === 0) {
      initQuestion(room.currentQuestion);
    }
  }, [room.currentQuestion.questionState]);

  //when question changes
  useEffect(() => {
    const questionLabel = room.currentQuestion.value;
    if (!isEmpty(questionLabel)) {
      if (isEmpty(questionLabel.entityMap)) questionLabel.entityMap = {};
      const contentState = convertFromRaw(questionLabel);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [room.currentQuestion]);

  useEffect(() => {
    let stateToHTMLOptions = {
      blockStyleFn: (block: any) => {
        let data = block.getData();
        if (data.size === 0) return;
        let style = {};
        if (data.get('text-align'))
          style = {
            ...style,
            textAlign: data.get('text-align'),
          };
        return {
          style,
        };
      },
      inlineStyleFn: (styles: any) => {
        let size = styles.filter((value: string) => value.startsWith('fontsize-')).first();
        let color = styles.filter((value: string) => value.startsWith('color-')).first();
        let bgColor = styles.filter((value: any) => value.startsWith('bgcolor-')).first();

        let style = {};
        if (color)
          style = {
            ...style,
            color: color.replace('color-', ''),
          }; //color
        if (size)
          style = {
            ...style,
            fontSize: size.replace('fontsize-', ''),
          }; //fontsize
        if (bgColor)
          style = {
            ...style,
            backgroundColor: bgColor.replace('bgcolor-', ''),
          }; //background color

        if (color) {
          return {
            element: 'span',
            style: style,
          };
        }
      },
    };

    let htmlOutput = stateToHTML(editorState.getCurrentContent(), stateToHTMLOptions);
    setQuestionDisplay(htmlOutput);
  }, [editorState]);

  const getLockIcon = (isQuestionLocked: boolean, size: Size) => (
    <Box
      border={`5px solid ${colours.plLightPurple}`}
      padding="15px"
      style={{ borderRadius: '5%', alignSelf: 'center', margin: size === 'small' ? '10px' : '' }}
    >
      {isQuestionLocked ? <Lock /> : <Unlock />}
    </Box>
  );

  type fontSizesType = Record<Size, number>;
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const fontSizes: fontSizesType = {
          small: 28,
          medium: 40,
          large: 60,
          xlarge: 90,
        };

        return answerConfirmed ? (
          <Box display="flex" flexGrow={1} direction="column" maxWidth={'100vw'}>
            <Box display="flex" direction="row" justify="space-evenly" alignItems="center">
              <Box gapY={30} gapX={0}>
                <AppBar />
              </Box>
              <Heading
                style={{
                  alignSelf: size === 'small' ? 'center' : '',
                  fontSize: fontSizes[size as Size],
                  minWidth: 'fit-content',
                  padding: '15px 0px',
                }}
                level={4}
              >
                <QuestionTitle number={room.currentQuestion.order.toString()} />
              </Heading>
              <Box gapY={30} gapX={0}>
                {getLockIcon(room.currentQuestion.questionLocked, size as Size)}
              </Box>
            </Box>
            <Box
              display="flex"
              flexGrow={1}
              height="inherit"
              direction="column"
              background={backgroundColor}
            >
              <Box
                display="flex"
                direction="column"
                justify="space-between"
                overflow="auto"
                flexGrow={1}
              >
                <Box alignItems="center" justify="center" flexGrow={1} display="flex" height="100%">
                  <Heading
                    margin="none"
                    color={
                      answer
                        ? answerToFontColour[answer?.value as keyof typeof answerToFontColour]
                        : 'white'
                    }
                    level={1}
                    style={{
                      fontSize: '20vw',
                    }}
                  >
                    {answer?.value?.toUpperCase()}
                  </Heading>
                </Box>
                <Button
                  style={{
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    padding: '10px',
                    margin: '30px',
                    borderColor: answerToColour[answer?.value as keyof typeof answerToColour],
                  }}
                  label={!room.currentQuestion.questionLocked ? 'Change response' : 'Voting closed'}
                  color="light-5"
                  onClick={() => clearAnswer()}
                  icon={<Undo />}
                  disabled={room.currentQuestion.questionLocked}
                  primary
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexGrow={1}
            direction="column"
            maxHeight={'-webkit-fill-available'}
            maxWidth={'100vw'}
          >
            <Box
              display="flex"
              justify="space-evenly"
              direction="row"
              flexGrow={size !== 'small' ? 1 : 0}
              alignItems={size === 'small' ? 'center' : undefined}
            >
              <Box
                margin="10px"
                alignItems="center"
                display="flex"
                justify="space-between"
                direction="column"
                flexGrow={size !== 'small' ? 1 : 0}
              >
                <Box gapY={15} gapX={0}>
                  <AppBar />
                </Box>
                <Box width="100%" display="flex" direction="row">
                  {size !== 'small' && <RoomBadge roomId={room.roomId} />}
                  {size !== 'small' &&
                    getLockIcon(room.currentQuestion.questionLocked, size as Size)}
                </Box>
              </Box>
              {size !== 'small' && (
                <Box overflowVertical="auto" display="flex" flexGrow={4} padding={'0 15px'}>
                  <Box
                    border={`1px solid ${colours.plBrightPurple}`}
                    display="flex"
                    direction="column"
                    flexGrow={1}
                    borderRadius="5px"
                    zIndex="1"
                    padding="20px"
                    margin="10px"
                    height="inherit"
                    overflowVertical="scroll"
                  >
                    {room.currentQuestion.order && (
                      <QuestionTitle
                        number={room.currentQuestion.order.toString()}
                        size={Size.medium}
                      />
                    )}
                    {questionDisplay && (
                      <Text
                        size="xlarge"
                        margin={{
                          horizontal: 'small',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: questionDisplay,
                        }}
                      />
                    )}
                  </Box>
                </Box>
              )}
              {size === 'small' && room.currentQuestion.order && (
                <Heading
                  style={{
                    justifySelf: 'start',
                    alignSelf: size === 'small' ? 'center' : '',
                    fontSize: fontSizes[size as Size],
                    minWidth: 'fit-content',
                    padding: '15px 0px',
                  }}
                  level={4}
                >
                  <QuestionTitle number={room.currentQuestion.order.toString()} />
                </Heading>
              )}
              {size === 'small' && (
                <Box gapY={30} gapX={0}>
                  {getLockIcon(room.currentQuestion.questionLocked, size as Size)}
                </Box>
              )}
            </Box>
            {size === 'small' && (
              <Box
                direction="column"
                style={{
                  flexGrow: '1',
                  zIndex: '1',
                  border: `1px solid ${colours.plBrightPurple}`,
                  padding: '10px',
                  borderRadius: '5px',
                  margin: '5px',
                  overflowY: 'scroll',
                }}
              >
                {questionDisplay && (
                  <Text
                    size="xlarge"
                    margin={{
                      horizontal: 'small',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: questionDisplay,
                    }}
                  />
                )}
              </Box>
            )}
            {!isEmpty(answer) ? (
              <Box display="flex" direction="row" gapX={5} margin="10px 5px 5px 0">
                <AnswerCard
                  grow
                  size={size}
                  fontSize={fontSizes[size as keyof typeof fontSizes]}
                  text={`CONFIRM ${answer?.value?.toUpperCase()}`}
                  color={answer?.color}
                  onClick={() => confirmAnswer(answer)}
                />
                <AnswerCard
                  size={size}
                  fontSize={fontSizes[size as keyof typeof fontSizes]}
                  color="status-disabled"
                  text={'UNDO'}
                  icon={<Undo size={'30'} />}
                  onClick={() => setAnswer(null)}
                />
              </Box>
            ) : (
              <Box
                display={size === 'small' ? 'flex' : 'grid'}
                gridTemplateColumns="1fr 1fr 1fr"
                direction={size === 'small' ? 'column' : 'row'}
                margin="10px 0"
                flexShrink={size === 'small' ? 1 : 0}
                gapX={5}
                gapY={5}
              >
                {renderAnswers(
                  room.currentQuestion.questionLocked,
                  fontSizes[size as keyof typeof fontSizes],
                  size as Size
                )}
              </Box>
            )}
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps: MapStateToProps<ConnectedProps, {}, AppState> = (state: AppState) => ({
  club: state.club,
  room: state.room,
});

export const RoomQuestion = connect(mapStateToProps)(RoomQuestionStaging);
