import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, MapStateToProps } from 'react-redux';

import { logoutUser } from '../actions/';

import { Button, DropButton, ResponsiveContext } from 'grommet';

import { AppState, ClubState, RoomState } from '../Types';
import styled from 'styled-components';
import { Size } from '../enums';
import { Box } from './layout/Box';

const SLogo = styled.img`
  max-height: 150px;
  max-width: ${({ size }: { size: Size }) =>
    size === 'small' ? '70px' : size === 'medium' ? '130px' : '180px'};
`;

type ConnectedProps = {
  room: RoomState;
  club: ClubState;
};
type DispatchProps = {
  logoutUser: () => void;
};
type AppBarProps = {
  side?: boolean;
  full?: boolean;
  qrCodeValue?: string;
  onCopyQRCode?: () => void;
  children?: JSX.Element | JSX.Element[];
};

type Props = ConnectedProps & DispatchProps & AppBarProps;

const DropContent = ({ logoutUser }: DispatchProps) => {
  const history = useHistory();
  return (
    <Box display="flex" direction="column" padding="10px" gapY={10}>
      <Button
        style={{ borderRadius: '5px' }}
        label="Back"
        onClick={() => {
          history.push('/home');
        }}
      />
      <Button style={{ borderRadius: '5px' }} label="Logout" onClick={() => logoutUser()} />
    </Box>
  );
};

const AppBarStaging = (props: Props) => {
  const { side, room, club, qrCodeValue, children, full, logoutUser, ...rest } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        return side ? (
          <Box
            zIndex="1"
            padding="15px 10px"
            display="flex"
            direction={size === 'small' ? 'column' : 'row'}
            alignItems="center"
            margin="10px"
          >
            <Box display="flex" direction="column" alignItems="center">
              <DropButton
                open={open}
                dropAlign={{
                  top: 'bottom',
                }}
                style={{ paddingBottom: 10 }}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                dropContent={<DropContent logoutUser={logoutUser} />}
              >
                <SLogo size={size as Size} src={`/club_logos/400/${club.id}.png`} />
              </DropButton>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            direction="column"
            justify="space-between"
            alignItems={full ? 'center' : undefined}
            padding={size !== 'small' ? '50px' : undefined}
            {...rest}
          >
            <DropButton
              open={open}
              dropAlign={{
                top: 'bottom',
              }}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              dropContent={<DropContent logoutUser={logoutUser} />}
            >
              <SLogo size={size as Size} src={`/club_logos/400/${props.club.id}.png`} />
            </DropButton>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps: MapStateToProps<ConnectedProps, AppBarProps, AppState> = (
  state: AppState
) => ({
  club: state.club,
  room: state.room,
});

const mapDispatchToProps = {
  logoutUser,
};

export const AppBar = connect(mapStateToProps, mapDispatchToProps)(AppBarStaging);
