import React from 'react';
import styled from 'styled-components';
import { Size } from '../enums';

type Props = {
  number: string;
  size?: Size;
};

const fontSizes: Record<Size, number> = {
  small: 20,
  medium: 40,
  large: 60,
  xlarge: 90,
};

const S_RoomHeader = styled('span')<{ size: string }>`
  color: #37003c;
  font-family: Premier League HeavyItalic;
  text-transform: uppercase;
  line-height: 24px;
  font-weight: bolder;

  font-size: ${(props) => {
    if (!props.size) return `${fontSizes['small']}px`;
    if (props.size === 'medium') return `${fontSizes['medium']}px`;
    if (props.size === 'large') return `${fontSizes['large']}px`;
    if (props.size === 'xlarge') return `${fontSizes['xlarge']}px`;
    return `${fontSizes['small']}px`;
  }};
`;

export const QuestionTitle = ({ number, size }: Props) => {
  return <S_RoomHeader size={size as string}>{number}. Club Approval</S_RoomHeader>;
};
