import { GET_ERRORS, SET_SOCKET_CONNECTED } from '../actions';

export type ErrorsState = {
  connected: boolean;
  success: boolean;
  errors: string[];
};

const initialState = {} as ErrorsState;
export default function errorReducer(state: ErrorsState = initialState, action: any): ErrorsState {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case SET_SOCKET_CONNECTED:
      return {
        ...state,
        connected: action.payload,
      };
    default:
      return state;
  }
}
