import { SET_CURRENT_USER } from '../actions';
import isEmpty from 'is-empty';
import { User } from '../Types';

type AuthState = {
  isAuthenticated: boolean;
  user: User;
};

const initialState = {
  isAuthenticated: false,
  user: {} as User,
};
export default function authReducer(state: AuthState = initialState, action: any): AuthState {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    default:
      return state;
  }
}
