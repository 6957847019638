import io from 'socket.io-client';
import jwt_decode from 'jwt-decode';
import { dbUrl } from './config';

const connectionURL = dbUrl;

// const connectionURL = process.env.REACT_APP_DB_URL
//   ? process.env.REACT_APP_DB_URL
//   : 'https://pl-uks-boardvoting-server-poc-app.azurewebsites.net';

const socketQueryArr = [];
//auto-set clubId in socket
if (localStorage.jwtToken) {
  socketQueryArr.push(
    `clubId=${
      jwt_decode<{
        club: string;
        exp: number;
      }>(localStorage.jwtToken).club
    }`
  );

  //assume user is authed, try and connect to room
  const url = window.location.href;
  if (url.match(/(\/event\/)([aA-zZ]{2})([0-9]{3})/gi)) {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    const eventInURL = url.match(/(\/event\/)([aA-zZ]{2})([0-9]{3})/g)[0];
    socketQueryArr.push(`roomId=${eventInURL.split('/event/')[1]}`);
  }
}

var socket = (io as any).connect(connectionURL, {
  query: socketQueryArr.join('&'),
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionAttempts: 50000,
});

export const SET_CONNECTED_CLUBS = 'SET_CONNECTED_CLUBS';
export const SET_ROOM_ACTIVE = 'SET_ROOM_ACTIVE';
export const SET_ROOM_COMPLETE = 'SET_ROOM_COMPLETE';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const SET_ROOM_TOTAL_QUESTIONS = 'SET_ROOM_TOTAL_QUESTIONS';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_SOCKET_CONNECTED = 'SET_SOCKET_CONNECTED';

//LISTENERS
export function initSockets(store: any) {
  socket.emit('client-connection', null);

  store.dispatch({
    type: SET_SOCKET_CONNECTED,
    payload: true,
  });

  socket.io.on('error', (error: any) => {
    console.log(error);
    store.dispatch({
      type: SET_SOCKET_CONNECTED,
      payload: false,
    });
  });

  socket.io.on('reconnect', () => {
    console.log('reconnected');
    store.dispatch({
      type: SET_SOCKET_CONNECTED,
      payload: true,
    });
    window.location.reload();
  });

  socket.on('serverActivateRoom', (data: any) => {
    console.log('serverActivateRoom');
    store.dispatch({
      type: SET_ROOM_ACTIVE,
      payload: data,
    });
  });

  socket.on('serverRoomTotalQuestions', (data: any) => {
    store.dispatch({
      type: SET_ROOM_TOTAL_QUESTIONS,
      payload: data,
    });
  });

  socket.on('serverRoomQuestions', (data: any) => {
    console.log('serverRoomQuestions', data);
    store.dispatch({
      type: SET_QUESTIONS,
      payload: data,
    });
  });

  socket.on('serverCompleteRoom', (data: any) => {
    console.log('serverCompleteRoom');
    store.dispatch({
      type: SET_ROOM_COMPLETE,
      payload: data,
    });
  });

  socket.on('serverUpdateQuestion', function (data: any) {
    console.log('serverUpdateQuestion', data);
    store.dispatch({
      type: SET_CURRENT_QUESTION,
      payload: data,
    });
  });

  socket.on('serverUpdateConnectedClubs', function (data: any) {
    console.log('serverUpdateConnectedClubs');
    store.dispatch({
      type: SET_CONNECTED_CLUBS,
      payload: data.clubs,
    });
  });

  socket.on('serverUpdateQuestionsOrder', function (data: any) {
    console.log('serverUpdateQuestionsOrder');
    store.dispatch({
      type: SET_QUESTIONS,
      payload: data,
    });
  });

  socket.on('server:connected_clubs', (data: any) => {
    console.log('server:connected_clubs');
    store.dispatch({
      type: SET_CONNECTED_CLUBS,
      payload: data,
    });
  });
}

//EMITTERS
export function joinRoom(roomId: any, clubId: any) {
  socket.emit('joinRoom', {
    roomId,
    clubId,
  });
}

export function setRoomActive(roomId: any) {
  socket.emit('activateRoom', {
    roomId,
  });
}

export function socketClubConnected(roomId: any, clubId: any) {
  console.log('socketClubConnected called');
  socket.emit('clientClubConnected', {
    roomId: roomId,
    clubId: clubId,
  });
}

export function setSocketClubID(clubId: any) {
  console.log(`----\nsetSocketClubID`);
  console.log(`broadcasting club ID to be: ${clubId}`);
  socket.emit('clientSetClubID', {
    clubId: clubId,
  });
}

export function socketClubResponse(roomId: any, clubId: any, value?: Response | null) {
  socket.emit('clientClubResponse', {
    roomId: roomId,
    clubId: clubId,
    value: value,
  });
}

export function socketSetNextQuestion(roomId: any) {
  socket.emit('clientSetNextQuestion', {
    roomId: roomId,
  });
}

export function socketSetPrevQuestion(roomId: any) {
  socket.emit('clientSetPrevQuestion', {
    roomId: roomId,
  });
}

export function socketSetQuestionLocked(roomId: any, questionLocked: any) {
  socket.emit('clientSetQuestionLock', {
    roomId: roomId,
    questionLocked: questionLocked,
  });
}

export function socketUpdateQuestionLabel(roomId: any) {
  socket.emit('hostUpdatedQuestions', {
    roomId: roomId,
  });
}

export function socketUpdateQuestionsOrder(roomId: any, clubsArr: any) {
  socket.emit('hostUpdatedQuestionsOrder', {
    roomId: roomId,
    clubsArr: clubsArr,
  });
}

export function socketDisconnect() {
  socket.disconnect();
}
