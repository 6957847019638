import { combineReducers } from 'redux';
import auth_reducer from './auth_reducer';
import club_reducer from './club_reducer';
import room_reducer from './room_reducer';
import login_error_reducer from './login_error_reducer';

const rootReducer = combineReducers({
  auth: auth_reducer,
  club: club_reducer,
  room: room_reducer,
  errors: login_error_reducer,
});

export default rootReducer;
