import { SET_CURRENT_CLUB } from '../actions';
import { Role } from '../Types';

type ClubState = {
  role: Role | null;
  id: string;
  logo: string;
};

const initialState = {
  role: null,
  id: '',
  logo: '',
};

export default function authReducer(state: ClubState = initialState, action: any): ClubState {
  switch (action.type) {
    case SET_CURRENT_CLUB:
      return action.payload
        ? {
            ...state,
            role: action.payload.role,
            id: action.payload._id,
            logo: action.payload.logo,
          }
        : initialState;
    default:
      return state;
  }
}
