import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { connect, MapStateToProps } from 'react-redux';
import axios from 'axios';
import isEmpty from 'is-empty';
import { History } from 'history';

import { Button, FormField, TextInput } from 'grommet';

import { AppBar } from '../components/';
import { setUserRoom } from '../actions/';
import { AppState, ClubState } from '../Types';
import { Box } from '../components/layout/Box';

type ConnectedProps = {
  club: ClubState;
};

type DispatchProps = {
  setUserRoom: (roomId: string, clubId: string, history: History) => void;
};

type Props = ConnectedProps & DispatchProps;

const JoinStaging = ({ club, setUserRoom }: Props) => {
  const [roomId, setRoomId] = useState('');
  const [roomError, setRoomError] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (localStorage.roomId) {
      setRoomId(localStorage.roomId);
    }
  }, []);

  const location = useLocation<{ roomId: string }>();
  useEffect(() => {
    if (!!location?.state?.roomId) {
      setRoomId(location.state.roomId);
    }
  }, [location?.state?.roomId]);

  const verifyRoomExists = () => {
    if (roomId !== '') {
      axios
        .get(`/room/${roomId}`)
        .then((res) => {
          if (!isEmpty(res.data)) {
            localStorage.setItem('roomId', roomId);
            setUserRoom(roomId, club.id, history);
            return;
          } else {
            setRoomError('Event does not exist.');
          }
        })
        .catch((err) => setRoomError(err));
    } else {
      setRoomError('Please provide an event ID.');
    }
  };

  return (
    <Box padding="50px">
      <AppBar full />
      <Box display="flex" direction="column" overflowHorizontal="hidden">
        <Box display="flex" direction="column" justify="center" alignItems="center" gapY={10}>
          <Box width="300px" display="flex" direction="column" gapY={30}>
            <FormField htmlFor="room-id" label="Event ID" error={roomError}>
              <TextInput
                id="event-id"
                placeholder="ID of the event to join"
                value={roomId}
                onChange={(e) => setRoomId(e.target.value.toUpperCase())}
              />
            </FormField>
            <Box display="flex" direction="row" justify="space-between" margin="25px 5px 0">
              <Button
                style={{ borderRadius: '5px' }}
                label="Back"
                onClick={() => {
                  history.goBack();
                }}
              />
              <Button
                style={{ borderRadius: '5px' }}
                primary
                label="Join"
                onClick={() => verifyRoomExists()}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps: MapStateToProps<ConnectedProps, {}, AppState> = (state: AppState) => ({
  club: state.club,
});

const mapDispatchToProps = {
  setUserRoom,
};

export const Join = connect(mapStateToProps, mapDispatchToProps)(JoinStaging);
