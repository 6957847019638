import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import jwt_decode from 'jwt-decode';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import axios from 'axios';

import { setCurrentUser, setAuthToken, setClubState, logoutUser, setCurrentRoom } from './actions';
import { initSockets } from './sockets_client';
import store from './store';
import { dbUrl } from './config';

initSockets(store);

axios.defaults.baseURL = `${dbUrl}/api`;

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  //if username and password provided in URL, trust it
  const URLParam = new URLSearchParams(window.location.search);
  console.log(!URLParam.get('username'));
  console.log(!URLParam.get('password'));
  if (!URLParam.get('username') || !URLParam.get('password')) {
    const token = localStorage.jwtToken;
    setAuthToken(token);
    const decoded = jwt_decode<{
      club: string;
      exp: number;
    }>(token);

    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      // Logout user
      store.dispatch(logoutUser());
      window.location.href = './login';
    } else {
      //login user
      store.dispatch(setCurrentUser(decoded));
      store.dispatch(setClubState(decoded.club));
    }
  }
}

// Check for token to keep user in room
if (localStorage.roomId) {
  store.dispatch(setCurrentRoom(localStorage.roomId));
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
reportWebVitals();
