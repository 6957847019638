export const clamp = (min: number, max: number, v: number) => Math.min(Math.max(v, min), max);

const distance1D = (a: any, b: any) => Math.abs(a - b);

/*
  Distance
  Returns the distance between two n dimensional points.
  @param [object/number]: x and y or just x of point A
  @param [object/number]: (optional): x and y or just x of point B
  @return [number]: The distance between the two points
*/

export const isNum = (v: any) => typeof v === 'number';

export const isPoint = (point: any) =>
  Object.prototype.hasOwnProperty.call(point, 'x') &&
  Object.prototype.hasOwnProperty.call(point, 'y');

export const distance = (a: any, b: any) => {
  if (isNum(a) && isNum(b)) {
    // 1D dimensions
    return distance1D(a, b);
  } else if (isPoint(a) && isPoint(b)) {
    // Multi-dimensional
    const xDelta = distance1D(a.x, b.x);
    const yDelta = distance1D(a.y, b.y);
    const zDelta = distance1D(a.z, b.z);

    return Math.sqrt(xDelta ** 2 + yDelta ** 2 + zDelta ** 2);
  }
};

// Prevent rapid reverse swapping
const buffer = 5;

export const findIndex = (i: any, yOffset: any, positions: any) => {
  let target = i;
  const { top, height } = positions[i];
  const bottom = top + height;

  // If moving down
  if (yOffset > 0) {
    const nextItem = positions[i + 1];
    if (nextItem === undefined) return i;

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const swapOffset = distance(bottom, nextItem.top + nextItem.height / 2) + buffer;
    if (yOffset > swapOffset) target = i + 1;

    // If moving up
  } else if (yOffset < 0) {
    const prevItem = positions[i - 1];
    if (prevItem === undefined) return i;

    const prevBottom = prevItem.top + prevItem.height;
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const swapOffset = distance(top, prevBottom - prevItem.height / 2) + buffer;
    if (yOffset < -swapOffset) target = i - 1;
  }

  return clamp(0, positions.length, target);
};
