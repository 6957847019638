import React, { CSSProperties } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  display?: 'flex' | 'block' | 'none' | 'grid';
  gridTemplateColumns?: string;
  /****** Container Props ********/
  overflow?: 'auto' | 'scroll';
  overflowVertical?: 'auto' | 'scroll' | 'hidden';
  overflowHorizontal?: 'auto' | 'scroll' | 'hidden';
  direction?: 'row' | 'column' | 'row-responsive' | 'row-reverse' | 'column-reverse';
  position?: 'static' | 'absolute';
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial'
    | 'inherit';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  cursor?: 'pointer' | 'move';
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';
  alignSelf?: 'center' | 'start' | 'end';
  /****** Child Props ********/
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number;
  flex?: string;
  /****** Common Layout Props ********/
  padding?: string;
  margin?: string;
  background?: string;
  color?: string;
  width?: string;
  opacity?: string;
  border?: string;
  borderRadius?: string;
  zIndex?: string;
  transition?: string;
  height?: string;
  maxWidth?: string;
  boxShadow?: string;
  gapX?: number;
  gapY?: number;
  maxHeight?: string;
  minWidth?: string;
  minHeight?: string;
  borderBottom?: string;
  onClick?: () => unknown;
  style?: CSSProperties;
  children: unknown | unknown[] | null;
};

const StyledDiv = styled.div.attrs((props: Props) => props)`
  > * {
    margin: ${(props) => `${props.gapY || 0}px ${props.gapX || 0}px`};
  }
  display: ${(props) => props.display};
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  position: ${(props) => props.position};
  overflow: ${(props) => props.overflow || ''};
  overflow-y: ${(props) => props.overflowVertical || ''};
  overflow-x: ${(props) => props.overflowHorizontal || ''};
  align-self: ${(props) => props.alignSelf};
  opacity: ${(props) => props.opacity};
  transition: ${(props) => props.transition};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  z-index: ${(props) => props.zIndex};
  box-shadow: ${(props) => props.boxShadow};
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  justify-content: ${(props) => props.justify};
  flex-direction: ${(props) => props.direction};
  flex-grow: ${(props) => props.flexGrow};
  flex-basis: ${(props) => props.flexBasis};
  flex-shrink: ${(props) => props.flexShrink};
  flex-wrap: ${(props) => props.flexWrap};
  flex: ${(props) => props.flex};
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-width: ${(props) => props.maxWidth};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  min-width: ${(props) => props.minWidth};
  cursor: ${(props) => props.cursor};
`;

export const Box = (props: Props) => {
  const {
    display,
    gridTemplateColumns,
    overflow,
    overflowVertical,
    overflowHorizontal,
    justify,
    alignSelf,
    direction,
    flexGrow,
    flexBasis,
    flexShrink,
    opacity,
    transition,
    border,
    color,
    borderRadius,
    zIndex,
    flexWrap,
    flex,
    alignItems,
    margin,
    padding,
    position,
    width,
    height,
    maxWidth,
    maxHeight,
    minHeight,
    boxShadow,
    gapX,
    gapY,
    minWidth,
    background,
    onClick,
    children,
    cursor,
    style,
  } = props;

  return (
    <StyledDiv
      display={display}
      gridTemplateColumns={gridTemplateColumns}
      overflow={overflow}
      overflowVertical={overflowVertical}
      overflowHorizontal={overflowHorizontal}
      justify={justify}
      alignSelf={alignSelf}
      direction={direction}
      flexGrow={flexGrow}
      flexBasis={flexBasis}
      flexShrink={flexShrink}
      flexWrap={flexWrap}
      position={position}
      flex={flex}
      alignItems={alignItems}
      margin={margin}
      padding={padding}
      opacity={opacity}
      transition={transition}
      boxShadow={boxShadow}
      width={width}
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      minHeight={minHeight}
      gapX={gapX}
      gapY={gapY}
      minWidth={minWidth}
      background={background}
      color={color}
      border={border}
      borderRadius={borderRadius}
      zIndex={zIndex}
      cursor={cursor}
      style={style}
      onClick={onClick}
    >
      {children}
    </StyledDiv>
  );
};
