import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, MapStateToProps } from 'react-redux';
import { AppState, AuthState } from '../Types';

type PrivateRouteProps = {
  path: string;
  component: React.FC;
};

type ConnectedProps = {
  auth: AuthState;
};

type Props = PrivateRouteProps & ConnectedProps;

const PrivateRoute = (props: Props) => {
  const { component: Component, auth, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props: any) =>
        !!auth.isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const mapStateToProps: MapStateToProps<ConnectedProps, PrivateRouteProps, AppState> = (
  state: AppState
) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
