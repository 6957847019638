import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, MapStateToProps } from 'react-redux';

import { Button } from 'grommet';
import { Add, Beacon, Logout, Play } from 'grommet-icons';

import { logoutUser } from '../actions';

import { AppBar } from '../components/';
import { AppState, ClubState, Role } from '../Types';
import { Box } from '../components/layout/Box';

type ConnectedProps = {
  club: ClubState;
};

type DispatchProps = {
  logoutUser: () => void;
};

type Props = ConnectedProps & DispatchProps;

const HomeStaging = ({ club, logoutUser }: Props) => {
  const history = useHistory();

  const isHost = club.role === Role.host;

  return (
    <Box display="flex" direction="column" justify="space-around" height={'100vh'}>
      <AppBar full />
      <Box display="flex" direction="column" overflowHorizontal="hidden">
        <Box
          display="flex"
          direction="column"
          padding="50px"
          justify="center"
          alignItems="center"
          gapY={10}
        >
          <Box display="flex" direction="column" gapY={15}>
            {isHost && (
              <Button
                style={{ borderRadius: '5px' }}
                icon={<Add />}
                size="large"
                label="Create Event"
                onClick={() => {
                  history.push('/create');
                }}
              />
            )}
            <Button
              style={{ borderRadius: '5px' }}
              icon={<Play />}
              size="large"
              label="Join Event"
              onClick={() => {
                history.push('/join');
              }}
            />
            <Button
              style={{ borderRadius: '5px' }}
              icon={<Logout />}
              size="large"
              label="Logout"
              onClick={() => {
                logoutUser();
              }}
            />
          </Box>
          <Box>
            <Beacon size="medium" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = {
  logoutUser,
};

const mapStateToProps: MapStateToProps<ConnectedProps, {}, AppState> = (state: AppState) => ({
  club: state.club,
});

export const Home = connect(mapStateToProps, mapDispatchToProps)(HomeStaging);
