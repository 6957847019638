import React from 'react';

import { AppBar, HeaderCard } from '../';
import { useHistory } from 'react-router';
import { Box } from '../layout/Box';
import styled from 'styled-components';
import { Button } from 'grommet';
import { Logout } from 'grommet-icons';

const S_WelcomeBox = styled.div`
  border-radius: 5px;
  background-color: #ececec;
  flex-direction: column;
  flex-grow: 1;
  padding: 50px 20px;
  text-align: center;
`;

export const RoomComplete = () => {
  const history = useHistory();
  return (
    <Box padding="50px">
      <AppBar full />
      <Box
        overflow="auto"
        flexGrow={1}
        padding="96px 24px"
        display="flex"
        direction="column"
        gapY={10}
      >
        <S_WelcomeBox>
          Event has been closed.
          <br />
          <br />
          Thank you.
        </S_WelcomeBox>
        <Button
          style={{ borderRadius: '5px' }}
          primary
          icon={<Logout />}
          label="Leave"
          onClick={() => {
            history.goBack();
          }}
        />
      </Box>
    </Box>
  );
};
