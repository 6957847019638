export enum Response {
  for = 'for',
  against = 'against',
  abstain = 'abstain',
}

export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
}
