import React from 'react';

import { Card, CardBody, Grid, Text } from 'grommet';
import { colours } from '../App';
import { Box } from './layout/Box';
import styled from 'styled-components';

type Props = {
  color: string;
  fontColor: string;
  label: string;
  value: number;
};

const S_Text = styled.span`
  font-family: Premier League HeavyItalic, Arial;
  color: ${(props) => props.color};
  font-size: 36px;
`;

export const ResponsesCard = ({ color, label, value, fontColor }: Props) => (
  <Card height="xsmall" style={{ borderRadius: '5px', width: '100%', flexGrow: 1 }}>
    <CardBody>
      <Grid fill columns={['2/3', '1/3']}>
        <Box
          display="flex"
          justify="center"
          alignItems="center"
          background={color ? color : 'transparent'}
        >
          <S_Text color={fontColor || 'auto'}>{label}</S_Text>
        </Box>
        <Box
          display="flex"
          justify="center"
          alignItems="center"
          style={{ fontSize: 50, fontWeight: 'bolder', color: colours.plDarkGrey }}
        >
          {value}
        </Box>
      </Grid>
    </CardBody>
  </Card>
);
