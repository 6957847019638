import React from 'react';
import { Button } from 'grommet';
import { Box } from './layout/Box';

type Props = {
  color?: string;
  text: string;
  onClick: () => void;
  fontSize: number;
  size: string;
  disabled?: boolean;
  icon?: JSX.Element;
  grow?: boolean;
};
export const AnswerCard = ({
  color,
  text,
  onClick,
  fontSize,
  size,
  disabled,
  grow,
  ...rest
}: Props) => (
  <Box flexGrow={grow ? 1 : undefined} height={size === 'small' ? '100px' : '200px'}>
    <Button
      style={{
        borderRadius: '5px',
        fontWeight: 'bold',
        textOverflow: 'clip',
        whiteSpace: 'pre-wrap',
        fontFamily: 'Premier League HeavyItalic',
        overflow: 'hidden',
        lineHeight: '1.3',
        fontSize: fontSize,
      }}
      primary
      disabled={disabled}
      color={color}
      label={text}
      fill
      onClick={onClick}
      {...rest}
    />
  </Box>
);
