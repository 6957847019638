import React from 'react';

export const QrCodeIcon = ({ color }: { color?: string }) => (
  <svg width="35" height="35" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.875 14.2812V0.875H14.2812V14.2812H0.875ZM3.3125 11.8438H11.8438V3.3125H3.3125V11.8438ZM0.875 30.125V16.7188H14.2812V30.125H0.875ZM3.3125 27.6875H11.8438V19.1562H3.3125V27.6875ZM16.7188 14.2812V0.875H30.125V14.2812H16.7188ZM19.1562 11.8438H27.6875V3.3125H19.1562V11.8438ZM26.7938 30.125V26.7938H30.125V30.125H26.7938ZM16.7188 20.0906V16.7188H20.05V20.0906H16.7188ZM20.05 23.4219V20.0906H23.4219V23.4219H20.05ZM16.7188 26.7938V23.4219H20.05V26.7938H16.7188ZM20.05 30.125V26.7938H23.4219V30.125H20.05ZM23.4219 26.7938V23.4219H26.7938V26.7938H23.4219ZM23.4219 20.0906V16.7188H26.7938V20.0906H23.4219ZM26.7938 23.4219V20.0906H30.125V23.4219H26.7938Z"
      fill={color ?? '#37003C'}
    />
  </svg>
);
