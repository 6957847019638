import { joinRoom, setSocketClubID, socketDisconnect } from '../sockets_client';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { Club, UserData } from '../Types';
import { History } from 'history';

//user actions
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
//club actions
export const SET_CURRENT_CLUB = 'SET_CURRENT_CLUB';
//room actions
export const SET_ROOM_CODE = 'SET_ROOM_CODE';
export const ACTIVATE_ROOM = 'ACTIVATE_ROOM';
//error actions
export const GET_ERRORS = 'GET_ERRORS';
export const SET_SOCKET_CONNECTED = 'SET_SOCKET_CONNECTED';

/* AUTH ACTIONS*/
// Login - get user token
export const loginUser = (userData: UserData) => (dispatch: any) => {
  axios
    .post(`/auth/login`, userData)
    .then((res) => {
      if (!res.data.success) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data,
        });
      } else {
        const { token } = res.data;
        localStorage.setItem('jwtToken', token);
        setAuthToken(token);
        const decoded = jwt_decode<{ club: string; exp: number }>(token);
        dispatch(setCurrentUser(decoded));
        dispatch(setClubState(decoded.club));
      }
    })
    .catch((err) => console.log(err));
};
// Set logged in user
export const setCurrentUser = (decoded: any) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
// Set auth token on login
export const setAuthToken = (token: any) => {
  if (token) {
    // Apply authorization token to every request if logged in
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    // Delete auth header
    axios.defaults.headers.common['Authorization'] = '';
  }
};
// Log user out
export const logoutUser = () => (dispatch: any) => {
  socketDisconnect();
  localStorage.removeItem('jwtToken');
  localStorage.clear();
  setAuthToken(false);
  dispatch(setCurrentUser(null));
  dispatch(setCurrentClub(null));
};

/* CLUB ACTIONS */
export const setClubState = (clubId: string) => (dispatch: any) => {
  console.log('setting club state to:', clubId);
  axios.get(`/club/${clubId}`).then((res) => {
    setSocketClubID(res.data._id);
    dispatch(setCurrentClub(res.data));
  });
};
// set current club
export const setCurrentClub = (club: Club | null) => {
  return {
    type: SET_CURRENT_CLUB,
    payload: club,
  };
};

/* ROOM ACTIONS */
export const setUserRoom =
  (roomId: string, clubId: string, history: History) => (dispatch: any) => {
    dispatch(setCurrentRoom(roomId));
    joinRoom(roomId, clubId);
    history.push('/event/' + roomId);
  };
// leave room
export const removeUserRoom = () => (dispatch: any) => {
  localStorage.removeItem('roomId');
  dispatch(setCurrentRoom(null));
};
// set current room
export const setCurrentRoom = (roomId: string | null) => {
  return {
    type: SET_ROOM_CODE,
    payload: roomId,
  };
};
